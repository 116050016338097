import React from 'react';

import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
} from 'reactstrap';
import Datetime from 'react-datetime';

require('moment/locale/pt-br');

class MonthPicker extends React.Component {
  render() {
    return (
      <Datetime
        renderInput={
          (props) => (
            <InputGroup>
              <Input
                {...props}
                disabled={this.props.disabled}
                value={this.props.value}
                innerRef={this.props.innerRef}
                readOnly={!this.props.disabled}
              />
              <InputGroupAddon
                id="btnVerCalendario"
                addonType="append"
                className='clickable'
                onClick={this.props.onClickCalendarButton}>
                <InputGroupText>
                  <i className="fa fa-calendar clickable-icon" />
                </InputGroupText>
              </InputGroupAddon>
              <UncontrolledTooltip placement="bottom" target='btnVerCalendario' delay={0}>
                Ver Calendário
              </UncontrolledTooltip>
            </InputGroup>
          )
        }
        viewDate={this.props.viewDate}
        timeFormat={false}
        locale="pt-br"
        onChange={this.props.onChange}
        dateFormat="M"
        value={this.props.value}
        closeOnSelect
      />
    );
  }
}

export default MonthPicker;
