import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import DiariosService from 'services/DiariosService';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import SituacaoDiario from 'assets/csv/situacoes-diario.json';

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoDiario.ABERTO,
      SituacaoDiario.CONSOLIDADO,
    ];

    this.state = {
      diarios: [],
      loading: true,
    }

    this.diariosService = new DiariosService();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.carregarDiariosDoProfessor();
    }
  }

  async carregarDiariosDoProfessor() {
    try {
      this.setState({ loading: true });

      const data = await this.diariosService
        .carregarDiariosDoProfessorPorAnoLetivo(this.props.idAnoLetivo, 1, 999999, '');

      const diarios = data.diarios.filter((d) => d.id !== this.props.diario.id && 
        d.serie === this.props.diario.serie && 
        d.cicloEnsino == CicloEnsino.ENSINO_FUNDAMENTAL_2.value
      );

      diarios.forEach((diario) => {
        Object.assign(diario, {
          situacao_str: this.situacoes[diario.situacao].label,
          copiar: false,
        });
      });

      this.setState({ diarios, loading: false, diariosState: '' });

    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar diários do professor',
      });
      this.props.callback(null);
    }
  }

  copiar() {
    if (!this.state.diarios.some((diario) => diario.copiar)) {
      this.setState({ diariosState: 'has-danger' });
      return;
    }

    this.props.callback(this.state.diarios.filter((diario) => diario.copiar));
  }

  render() {
    return (
      <Modal
        className="xxl-modal"
        isOpen={this.props.isOpen}>
        <ModalBody>
          {
            this.state.loading ? <div align="center" style={{ margin: 50 }}>
              <Loader
                type="Oval"
                color="#053d7c"
                height="50"
                width="50" />
            </div> : <div align="center">
              <Table>
                <thead className="text-primary">
                  <tr>
                    <th style={{ width: '10%' }}>Diário</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Turma</th>
                    <th style={{ width: '35%' }}>Escola</th>
                    <th style={{ width: '25%' }}>Curso</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>Situação</th>
                    <th style={{ width: '10%', textAlign: 'center' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.diarios.map((diario, idx) => <tr key={idx}>
                      <td>{diario.nome}</td>
                      <td style={{ textAlign: 'center' }}>{diario.nomeTurma}</td>
                      <td>{diario.escola}</td>
                      <td>{diario.curso}</td>
                      <td style={{ textAlign: 'center' }}>{diario.situacao_str}</td>
                      <td style={{ textAlign: 'center' }}>
                        <FormGroup check>
                          <Label className="form-check-label">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              checked={diario.copiar || false}
                              onChange={(e) => {
                                Object.assign(diario, {
                                  copiar: e.target.checked,
                                });
                                this.setState({
                                  diariosState: '',
                                })
                              }} />
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </td>
                    </tr>)
                  }
                </tbody>
              </Table>
              <FormGroup className={'has-label has-danger'}>
                {this.state.diariosState === 'has-danger' ? (
                  <label className="error">
                    Selecione ao menos um diário
                  </label>
                ) : null}
              </FormGroup>
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => { this.props.callback(null); }}>
            Cancelar
          </Button>
          <Button
            color={'primary'}
            type="button"
            onClick={() => this.copiar()}>
            Copiar
          </Button>&nbsp;&nbsp;&nbsp;
        </ModalFooter>
      </Modal >
    );
  }
}

export default ConfirmModal;
