import Noticias from 'views/gestor/Noticias';
import TurmasPais from 'views/pais/TurmasPais';
import TurmaAluno from 'views/aluno/TurmaAluno';
import Ouvidoria from 'views/gestor/Ouvidoria';
import CadastroChamadoOuvidoria from 'views/gestor/CadastroChamadoOuvidoria';
import ChamadoOuvidoria from 'views/gestor/ChamadoOuvidoria';
import MatriculasOnlinePais from 'views/gestor/MatriculasOnlinePais';
import SolicitacoesMatriculasPais from 'views/pais/SolicitacoesMatriculasPais';
import SolicitarMatriculaPais from 'views/pais/SolicitarMatriculaPais';
import SolicitacaoMatricula from 'views/gestor/SolicitacaoMatricula';
import MuralFotosAluno from 'views/professor/MuralFotosAluno';

import DadosUsuario from 'views/comum/DadosUsuario';
import MudarSenha from 'views/comum/MudarSenha';

import Papel from 'assets/csv/papeis.json';

const routes = [
  {
    path: '/noticias',
    name: 'Notícias',
    icon: 'far fa-newspaper',
    component: Noticias,
    layout: '/pais',
    role: Papel.PAIS.value,
  },
  {
    path: '/turmas',
    name: 'Turmas',
    icon: 'fa fa-calendar',
    component: TurmasPais,
    layout: '/pais',
    role: Papel.PAIS.value,
  },
  {
    path: '/turmas/:idMatricula',
    component: TurmaAluno,
    redirect: true,
    layout: '/pais',
    role: Papel.PAIS.value,
  },
  {
    path: '/ouvidoria/chamados',
    component: Ouvidoria,
    redirect: true,
    layout: '/pais',
    role: Papel.PAIS.value,
  },
  {
    path: '/ouvidoria/chamados/cadastrar',
    component: CadastroChamadoOuvidoria,
    redirect: true,
    layout: '/pais',
    role: Papel.PAIS.value,
  },
  {
    path: '/ouvidoria/chamados/:idChamadoOuvidoria/editar',
    component: CadastroChamadoOuvidoria,
    redirect: true,
    layout: '/pais',
    role: Papel.PAIS.value,
  },
  {
    path: '/ouvidoria/chamados/:idChamadoOuvidoria',
    component: ChamadoOuvidoria,
    layout: '/pais',
    redirect: true,
    role: Papel.PAIS.value,
  },
  {
    path: '/matriculasonline',
    name: 'Matrículas Online',
    icon: 'fas fa-address-card',
    component: MatriculasOnlinePais,
    layout: '/pais',
    role: Papel.PAIS.value,
    matriculasOnline: true,
  },
  {
    path: `/matriculasonline/filho/:idFilho`,
    component: SolicitacoesMatriculasPais,
    layout: '/pais',
    redirect: true,
    role: Papel.PAIS.value,
  },
  {
    path: `/matriculasonline/anoletivo/:idAnoLetivo/filho/:idFilho/solicitar`,
    component: SolicitarMatriculaPais,
    layout: '/pais',
    redirect: true,
    role: Papel.PAIS.value,
  },
  {
    path: '/matriculasonline/filho/:idFilho/solicitacaomatricula/:idSolicitacaoMatricula',
    component: SolicitacaoMatricula,
    redirect: true,
    layout: '/pais',
    role: Papel.PAIS.value,
  },
  {
    path: '/turmas/:idMatricula/muraisfotos/:idMuralFotos',
    component: MuralFotosAluno,
    layout: '/pais',
    redirect: true,
    role: Papel.PAIS.value,
  },
];

const routesProfile = [
  {
    path: '/dados',
    name: 'Meus Dados',
    mini: 'M',
    component: DadosUsuario,
    layout: '/pais',
    role: Papel.PAIS.value,
  },
  {
    path: '/mudarsenha',
    name: 'Alterar senha',
    mini: 'A',
    component: MudarSenha,
    layout: '/pais',
    role: Papel.PAIS.value,
  },

];

export { routesProfile };
export default routes;
