import BaseService from './BaseService';

class AvaliacaoConceitualService extends BaseService {
  carregarAvaliacaoConceitual(idDiario, idMateria, idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/avaliacaoconceitual/diario/${idDiario}/materia/${idMateria}/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAvaliacoesConceituaisMateria(idDiario, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/avaliacaoconceitual/diario/${idDiario}/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarAvaliacoesConceituais(idDiario, idMateria, avaliacoesConceituais) {
    const requestInfo = {
      method: 'post',
      url: `/avaliacaoconceitual/diario/${idDiario}/materia/${idMateria}`,
      data: JSON.stringify(avaliacoesConceituais),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default AvaliacaoConceitualService;
