import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';
import AdaptiveTable from 'views/components/AdaptiveTable';

import EntesService from 'services/EntesService';
import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import RegistroAulasService from 'services/RegistroAulasService';

import Turno from 'assets/csv/turnos.json';
import Papel from 'assets/csv/papeis.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class RegistroAulas extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-${props.diario.id}-registroaulas`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.fileInputRef = React.createRef();
    this.fileChange = this.fileChange.bind(this);

    this.state = {
      showAlert: false,
      showConfirmModal: false,

      error: null,

      turma: props.turma,
      diario: props.diario,
      totalAulasLancadas: 0,

      dadosTabela: {
        header: [],
        rows: [],
        total: undefined,
      },
    };

    this.registroAulasService = new RegistroAulasService();
  }

  async componentDidMount() {
    try {
      if (this.props.dadosEnte.registroAulasPeriodo) {
        const materia = this.props.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
          ? 'Campos de experiências' : 'Componentes curriculares';

        const objetivo = this.props.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
          ? 'Objetivos de aprendizagem e desenvolvimento' : 'Habilidades';

        if (this.props.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value) {
          this.setState({
            dadosTabela: Object.assign(this.state.dadosTabela, {
              header: [['Data início', '8%'], ['Data fim', '8%'], [materia, '20%'], [objetivo, '40%'], ['Autor', '15%']],
              columnAlign: ['text-center', 'text-center', 'text-left', 'text-left', 'text-center'],
              keys: ['data', 'dataFim', 'materia_str', 'objetoConhecimento', 'autor_str'],
            })
          });
        } else {
          this.setState({
            dadosTabela: Object.assign(this.state.dadosTabela, {
              header: [['Data', '10%'], [materia, '15%'], ['Nº Aulas', '10%'], [objetivo, '40%'], ['Autor', '20%']],
              columnAlign: ['text-center', 'text-left', 'text-center', 'text-left', 'text-center'],
              keys: ['data', 'materia_str', 'numeroAulas', 'objetoConhecimento', 'autor_str'],
            })
          });
        }
      } else {
        const materia = this.props.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
          ? 'Campo de experiências' : 'Componente curricular';

        const objetivo = this.props.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
          ? 'Objetivos de aprendizagem e desenvolvimento' : 'Objetos de conhecimento';

        this.setState({
          dadosTabela: Object.assign(this.state.dadosTabela, {
            header: [['Data', '10%'], [materia, '15%'], ['Nº Aulas', '10%'], [objetivo, '40%'], ['Autor', '20%']],
            columnAlign: ['text-center', 'text-left', 'text-center', 'text-left', 'text-center'],
            keys: ['data', 'materia_str', 'numeroAulas', 'objetoConhecimento', 'autor_str'],
          })
        });
      }

      await this.carregarRegistroAulas(this.paginaSelecionada, this.linhasPorPagina, '');

      const totalAulasLancadas = await this.registroAulasService
        .carregarTotalAulasLancadas(this.state.diario.id);

      this.setState({ totalAulasLancadas });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao carregar registros de aula',
      })
    }
  }

  async carregarRegistroAulas(pagina, linhasPorPagina, like) {
    try {
      const data = await this.registroAulasService
        .carregarRegistroAulas(this.props.diario.id, pagina, linhasPorPagina, like);

      const materiasPromises = data.registroAulas.map((registroAula) => (new Promise((resolve) => {
        const materias = this.registroAulasService.carregarMateriasRegistroAula(registroAula.id);
        resolve(materias);
      })));

      const materiasResults = await Promise.all(materiasPromises);

      const habilidadesPromises = data.registroAulas.map((registroAula) => (new Promise(async (resolve) => {
        const raoas = await this.registroAulasService
          .carregarObjetivosAprendizagem(registroAula.id);

        const rahs = await this.registroAulasService
          .carregarHabilidades(registroAula.id);

        let array1 = raoas.map((raoa) => raoa.objetivoAprendizagem.descricao);
        let array2 = rahs.map((rah) => rah.habilidade.descricao);

        resolve(array1.concat(array2));
      })));

      const habilidadesResults = await Promise.all(habilidadesPromises);

      data.registroAulas.forEach((registroAula, idx) => {
        Object.assign(registroAula, {
          materia_str: materiasResults[idx].map((materiasResult, idx) => <div key={idx}><div>{materiasResult.materia.nome}</div>{idx < materiasResults.length - 1 && <br />}</div>),
          autor_str: registroAula.vinculo.nomeUsuario,
          numeroAulas: registroAula.numeroAulas || '-',
          objetoConhecimento: habilidadesResults[idx].length > 0 ? habilidadesResults[idx].map((habilidade, idx) => <div key={idx}><div>{habilidade}</div>{idx < habilidadesResults.length - 1 && <br />}</div>) : registroAula.objetoConhecimento,
        });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, {
          rows: data.registroAulas,
          total: data.total,
        }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar registro de aulas cadastrados',
      });
    }
  }

  cadastrarRegistroAula() {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/registroaulas/cadastro/agrupado`);
  }

  editarRegistroAula(registroAula) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/registroaulas/edicao/agrupado/${registroAula.id}`);
  }

  registroAulaSelecionado(registroAula) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/registroaulas/agrupado/${registroAula.id}`);
  }

  confirmarRemocaoRegistroAula(registroAula) {
    this.setState({ registroAulaSelecionado: registroAula, showConfirmModal: true });
  }

  async removerRegistroAulaAgrupado() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Excluindo registro de aula...',
    });

    this.props.callbackMessage({
      showAlert: false,
    })

    try {
      await this.registroAulasService
        .removerRegistroAulaAgrupado(this.state.registroAulaSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Registro de aula removido com sucesso',
      })

      this.carregarRegistroAulas(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover escola',
      });
    }
  }

  fileChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 10000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Arquivo muito grande. O tamanho máximo para arquivo é 10MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            try {
              this.setState({
                showLoaderModal: true,
                loaderModalText: 'Enviando planilha...',
              });

              const arquivo = {
                nome: file.name,
                conteudo: matches[4],
                extensao: ext,
                tipo: matches[1],
              };

              await this.registroAulasService.enviarPlanilhaMultiseriadoAgrupado(
                this.state.diario.id, arquivo,
              );

              await this.carregarRegistroAulas(this.paginaSelecionada,
                this.linhasPorPagina, this.like);

              this.setState({
                showLoaderModal: false,
              });

              this.props.callbackMessage({
                showAlert: true,
                alertColor: 'success',
                alertMsg: 'Registros de aulas da planilha salvos com sucesso.',
              });
            } catch (e) {
              this.setState({
                showLoaderModal: false,
              });

              this.props.callbackMessage({
                showAlert: true,
                alertColor: 'danger',
                alertMsg: typeof (e) === 'string' ? e : 'Erro ao salvar registros de aula da planilha.',
              });
            }
          }
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  async downloadPlanilha() {
    const excel = await this.registroAulasService
      .downloadPlanilha(this.state.diario.id);

    const file = Utils.base64ToBlob(excel, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  render() {
    return <>
      <div hidden className="fileinput text-center">
        <input
          type="file"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={this.fileChange}
          ref={this.fileInputRef} />
      </div>
      <Row>
        <Col className='valignceter'>
          {
            !(this.props.dadosEnte.registroAulasPeriodo && this.props.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value) && <div style={{ margin: 20 }}>
              Total de registros: {this.state.totalAulasLancadas}
            </div>
          }
        </Col>
        <Col className="text-right">
          {
            !this.props.dadosEnte.registroAulasPeriodo && !this.props.dadosEnte.habilidadesRegistroAula && this.props.role === Papel.PROFESSOR.value && <UncontrolledDropdown>
              <DropdownToggle
                aria-haspopup={true}
                color="default"
                data-toggle="collapse"
                id="acoesDiarioDropdownMenuLink"
                onClick={(evt) => evt.stopPropagation()}
                nav>
                <Button color="defaul">
                  Planilha <b className="caret" />
                </Button>
              </DropdownToggle>
              <DropdownMenu aria-labelledby="acoesDiarioDropdownMenuLink" right>
                <DropdownItem
                  onClick={() => this.downloadPlanilha()} >
                  Download planilha
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.fileInputRef.current.click()} >
                  Enviar Planilha
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
        </Col>
      </Row>
      <AdaptiveTable
        disableActions={this.props.role !== Papel.PROFESSOR.value}
        selectedPage={this.paginaSelecionada}
        rowsPerPage={this.linhasPorPagina}
        disablePrintAction
        clickRows
        editButtonCallback={(registroAula) => this.editarRegistroAula(registroAula)}
        removeButtonCallback={(registroAula) => this.confirmarRemocaoRegistroAula(registroAula)}
        rowsPerPageCallback={(info) => {
          this.paginaSelecionada = 1;
          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
          this.linhasPorPagina = info.rowsPerPage;
          sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
          this.carregarRegistroAulas(this.paginaSelecionada, info.rowsPerPage, this.like);
        }}
        likeCallback={(text) => {
          this.like = text;
          this.paginaSelecionada = 1;
          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
          this.carregarRegistroAulas(this.paginaSelecionada, this.linhasPorPagina, text);
        }}
        paginatorCallback={(page) => {
          this.paginaSelecionada = page;
          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
          this.carregarRegistroAulas(this.paginaSelecionada, this.linhasPorPagina, this.like);
        }}
        rowCallback={(registroAula) => this.registroAulaSelecionado(registroAula)}
        data={this.state.dadosTabela} />
      <Row>
        <Col className="text-left">
          <Button
            color="primary"
            onClick={() => {
              this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.diario.idEscola}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`);
            }} >
            Voltar
          </Button>
        </Col>
        <Col className="text-right">
          {
            this.props.role === Papel.PROFESSOR.value && <>
              <Button
                color="primary"
                onClick={() => this.cadastrarRegistroAula()} >
                Cadastrar Registro de Aula
              </Button>
            </>
          }
        </Col>
      </Row>
      <ConfirmModal
        isOpen={this.state.showConfirmModal}
        callback={(confirm) => {
          this.setState({ showConfirmModal: false });
          if (confirm) {
            this.removerRegistroAulaAgrupado();
          } else {
            this.setState({ registroAulaSelecionado: null });
          }
        }}
        text='Confirme a exclusão do registro de aula' />
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={this.state.loaderModalText} />
    </>
  }
}


class RegistroAulasMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      error: null,
    };

    this.entesService = new EntesService();
    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurmaMultiseriado(this.props.match.params.idTurma);

      const turmas = await this.turmasService
        .carregarTurmasDaTurmaMultiseriado(this.props.match.params.idTurma);

      const fn0 = (t) => new Promise((resolve) => resolve(
        this.diariosService.carregarDiariosDaTurma(t.id),
      ));
      const actions0 = turmas.map(fn0);
      const diarios = await Promise.all(actions0);

      const turmaInfantil = turmas.find((turma) => turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value);
      const turmaFundamental = turmas.find((turma) => turma.curso.cicloEnsino === CicloEnsino.ENSINO_FUNDAMENTAL_1.value);
      const turmaEJA = turmas.find((turma) => turma.curso.cicloEnsino === CicloEnsino.EJA.value);

      const diarioInfantil = turmaInfantil ? diarios.find((d) => d[0].idTurma === turmaInfantil?.id)[0] : undefined;
      const diarioFundamental = turmaFundamental ? diarios.find((d) => d[0].idTurma === turmaFundamental?.id)[0] : undefined;
      const diarioEJA = turmaEJA ? diarios.find((d) => d[0].idTurma === turmaEJA?.id)[0] : turmaEJA;

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        turmaInfantil,
        turmaFundamental,
        turmaEJA,
        diarioInfantil,
        diarioFundamental,
        diarioEJA,
      });
    } catch (e) {
      console.log(e)
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario`}>Diário Único</Link></BreadcrumbItem>
            <BreadcrumbItem active>Registro de Aulas</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Registro de Aulas
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Tabs>
                <TabList>
                  {this.state.turmaInfantil && <Tab>Ensino infantil</Tab>}
                  {this.state.turmaFundamental && <Tab>Ensino fundamental</Tab>}
                  {this.state.turmaEJA && <Tab>Educação de Jovens e Adultos</Tab>}
                </TabList>
                {
                  this.state.turmaInfantil && <TabPanel>
                    <RegistroAulas
                      {...this.props}
                      turma={this.state.turmaInfantil}
                      diario={this.state.diarioInfantil}
                      callbackMessage={(msg) => this.setState(msg)}
                      dadosEnte={this.state.dadosEnte}
                    />
                  </TabPanel>
                }
                {
                  this.state.turmaFundamental && <TabPanel>
                    <RegistroAulas
                      {...this.props}
                      turma={this.state.turmaFundamental}
                      diario={this.state.diarioFundamental}
                      callbackMessage={(msg) => this.setState(msg)}
                      dadosEnte={this.state.dadosEnte}
                    />
                  </TabPanel>
                }
                {
                  this.state.turmaEJA && <TabPanel>
                    <RegistroAulas
                      {...this.props}
                      turma={this.state.turmaEJA}
                      diario={this.state.diarioEJA}
                      callbackMessage={(msg) => this.setState(msg)}
                      dadosEnte={this.state.dadosEnte}
                    />
                  </TabPanel>
                }
              </Tabs>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(RegistroAulasMultiseriado);
