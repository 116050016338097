import React from 'react';
import { withRouter } from 'hoc/withRouter';

import env from 'conf/env.json';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';

import AwsUtil from 'utils/AwsUtil';

import Loader from 'react-loader-spinner';
import Papa from 'papaparse';
import validarCpf from 'validar-cpf';
import isDateFormat from 'is-date-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmTypingModal from 'views/components/ConfirmTypingModal';

import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';

import Papel from 'assets/csv/papeis.json';

class Alunos extends React.Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-alunos`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.carregarAlunos = this.carregarAlunos.bind(this);
    this.editarAluno = this.editarAluno.bind(this);
    this.confirmarRemocaoAluno = this.confirmarRemocaoAluno.bind(this);
    this.removerAluno = this.removerAluno.bind(this);

    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,
      error: false,

      dadosTabela: {
        header: [['Nome', '35%'], ['Nome da mãe', '35%'], ['Data de nascimento', '10%']],
        columnAlign: ['text-left', 'text-left', 'text-center'],
        keys: ['nome', 'nomeMae', 'dataNascimento'],
        rows: [],
        total: undefined,
      },
    };
  }


  async componentDidMount() {
    try {
      await this.loginService.verificarPapel(this.props.role);

      const dadosEnte = await this.entesService.carregarDadosEnte();

			this.setState({ dadosEnte }, () => {
				this.carregarAlunos(this.paginaSelecionada, this.linhasPorPagina, '');
			});
    } catch (e) {
      this.setState({ error: true });
    }
  }

  async carregarAlunos(pagina, linhasPorPagina, like) {
    try {
      let data;
      if (this.state.dadosEnte.alunosPorEscola && (this.props.role === Papel.DIRETOR.value
        || this.props.role === Papel.SECRETARIO.value)) {
        data = await this.usuarioService
          .carregarUsuariosVinculadosDiretor(Papel.ALUNO.value, pagina, linhasPorPagina, like);
      } else {
        data = await this.usuarioService
          .carregarUsuarios(Papel.ALUNO.value, pagina, linhasPorPagina, like);
      }

      data.usuarios.forEach((aluno) => {
        Object.assign(aluno, {
          nome: <div>{aluno.nome} {aluno.pne && <i className="fal fa-universal-access fa-lg"/>}</div>,
        });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.usuarios, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar alunos cadastrados',
      });
    }
  }

  editarAluno(aluno) {
    this.props.history.push({
      pathname: `${this.props.layout}/alunos/edicao/${aluno.id}`,
    });
  }

  validarAluno = (aluno, linha) => {
    linha = linha + 1;
    
    const inep = aluno[0].trim().replace(/\s{2,}/g, ' ');
    const nis = aluno[1].trim().replace(/\s{2,}/g, ' ');
    const nome = aluno[2].trim().replace(/\s{2,}/g, ' ');
    const dataNascimento = aluno[3].trim().replace(/\s{2,}/g, ' ');
    const naturalidade = aluno[4].trim().replace(/\s{2,}/g, ' ');
    const nomeMae = aluno[5].trim().replace(/\s{2,}/g, ' ');
    const nomePai = aluno[6].trim().replace(/\s{2,}/g, ' ');
    let sexo = aluno[7].trim().replace(/\s{2,}/g, ' ');
    const endereco = aluno[8].trim().replace(/\s{2,}/g, ' ');
    let pne = aluno[9].trim().replace(/\s{2,}/g, ' ');
    const cpf = aluno[10].trim().replace(/\s{2,}/g, ' ');
    let etnia = aluno[11].trim().replace(/\s{2,}/g, ' ');
    let bolsaFamilia = aluno[12].trim().replace(/\s{2,}/g, ' ');
    let utilizaTransporte = aluno[13].trim().replace(/\s{2,}/g, ' ');

    if (inep !== '' && !/^[0-9]+$/.test(inep)) {
      throw new Error(`Erro na linha ${linha}: o ID Censo deve conter apenas números`);
    }

    if (nis !== '' && nis.length < 11) {
      throw new Error(`Erro na linha ${linha}: caso informado, o NIS deve possuir onze dígitos`);
    }

    if (nome === '') {
      throw new Error(`Erro na linha ${linha}: informe o nome de todos os alunos.`);
    }

    if (cpf !== '' && !validarCpf(cpf)) {
      throw new Error(`Erro na linha ${linha}: O CPF ${cpf} é inválido.`);
    }

    if (dataNascimento === '') {
      throw new Error(`Erro na linha ${linha}: informe a data de nascimento de todos os alunos.`);
    }

    if (!isDateFormat(dataNascimento, 'dd/mm/yyyy')) {
      throw new Error(`Erro na linha ${linha}: A data de nascimento ${dataNascimento} é inválida.`);
    }

    if (naturalidade === '') {
      throw new Error(`Erro na linha ${linha}: informe a naturalidade de todos os alunos.`);
    }

    if (nomeMae === '') {
      throw new Error(`Erro na linha ${linha}: informe o nome da mãe de todos os alunos.`);
    }

    if (sexo !== 'Masculino' && sexo !== 'Feminino') {
      throw new Error(`Erro na linha ${linha}: o sexo ${sexo} é inválido. Os valores válidos são Masculino ou Feminino.`);
    } else {
      sexo = sexo === 'Masculino' ? 0 : 1;
    }

    if (endereco === '') {
      throw new Error(`Erro na linha ${linha}: informe o endereço de todos os alunos.`);
    }

    if (etnia !== 'Branca' && etnia !== 'Preta'
      && etnia !== 'Parda' && etnia !== 'Amarela'
      && etnia !== 'Não declarada' && etnia !== 'Indígena') {
      throw new Error(`Erro na linha ${linha}: a etnia ${etnia} é inválida. Os valores válidos são Branca, Preta, Parda, Amarela ou Não declarada.`);
    } else {
      switch (etnia) {
        case 'Branca':
          etnia = 0;
          break;
        case 'Preta':
          etnia = 1;
          break;
        case 'Parda':
          etnia = 2;
          break;
        case 'Amarela':
          etnia = 3;
          break;
        case 'Indígena':
          etnia = 5;
          break;
        default:
          etnia = 4;
          break;
      }
    }

    if (pne !== 'Não' && pne !== 'Sim') {
      throw new Error(`Erro na linha ${linha}: A informação PNE ${pne} é inválida. Os valores válidos são Sim ou Não.`);
    } else {
      pne = pne === 'Não' ? 0 : 1;
    }

    if (bolsaFamilia !== 'Não' && bolsaFamilia !== 'Sim') {
      throw new Error(`Erro na linha ${linha}: A informação Bolsa familília ${bolsaFamilia} é inválida. Os valores válidos são Sim ou Não.`);
    } else {
      bolsaFamilia = bolsaFamilia === 'Não' ? 0 : 1;
    }

    if (utilizaTransporte !== 'Não' && utilizaTransporte !== 'Sim') {
      throw new Error(`Erro na linha ${linha}: A informação Transporte ${utilizaTransporte} é inválida. Os valores válidos são Sim ou Não.`);
    } else {
      utilizaTransporte = utilizaTransporte === 'Não' ? 0 : 1;
    }

    return {
      inep,
      nis,
      nome,
      dataNascimento,
      naturalidade,
      nomeMae,
      nomePai,
      sexo,
      endereco,
      pne,
      cpf,
      etnia,
      bolsaFamilia,
      utilizaTransporte,
    };
  }

  updateData(result) {
    this.fileInputRef.current.value = '';
    const alunos = [];

    try {
      result.data.forEach((aluno, idx) => {
        if (idx > 0) {
          alunos.push(this.validarAluno(aluno, idx));
        }
      });
      this.cadastrarAlunos(alunos);
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e.message || 'Erro: arquivo mal formatado',
      });
    }
  }

  handleFile(e) {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Carregando alunos...',
    });
    e.preventDefault();
    const file = e.target.files[0];
    const extension = file.name.split('.').pop();
    if (extension !== 'csv') {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro: informe apenas arquivos com extensão .csv',
      });
    } else {
      Papa.parse(file, {
        complete: this.updateData.bind(this),
        delimiter: ';',
        skipEmptyLines: true,
      });
    }
  }

  async cadastrarAlunos(alunos) {
    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Cadastrando alunos...',
    });

    try {
      await this.usuarioService.cadastrarAlunos(alunos);
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Alunos cadastrados com sucesso',
      });
      this.carregarAlunos(1, this.linhasPorPagina, '');
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar alunos',
      });
    }
  }

  confirmarRemocaoAluno(aluno) {
    this.setState({
      alunoSelecionado: aluno,
      showConfirmModal: true,
      deletionText: `${aluno.nome}`,
    });
  }

  async removerAluno(senha) {
    this.setState({ 
      showAlert: false, 
      showLoaderModal: true, 
      loaderModalText: 'Removendo aluno...',
   });

    try {
      await this.usuarioService
        .removerAluno(this.state.alunoSelecionado.id, senha);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Aluno removido com sucesso',
      });
      this.carregarAlunos(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (err) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: err || 'Erro ao remover aluno',
      });
    }
  }

  conteudoPagina() {
    return this.state.dadosEnte ? <Row>
      <Col md="12">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem active>Alunos</BreadcrumbItem>
        </Breadcrumb>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Alunos cadastrados</CardTitle>
          </CardHeader>
          <CardBody>
            <AdaptiveTable
              clickRows={true}
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disableActions={!(this.props.role === Papel.GESTOR.value
                || !this.state.dadosEnte.bloquearCadastroAlunos)}
              editButtonCallback={(aluno) => this.editarAluno(aluno)}
              removeButtonCallback={(aluno) => this.confirmarRemocaoAluno(aluno)}
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarAlunos(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarAlunos(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarAlunos(this.paginaSelecionada, this.linhasPorPagina, this.like);
              }}
              rowCallback={(aluno) => {
                this.props.history.push({
                  pathname: `${this.props.layout}/alunos/historico/${aluno.id}`,
                });
              }}
              data={this.state.dadosTabela}
              disablePrintAction
              disableRemoveAction={this.props.role !== Papel.GESTOR.value}/>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="6" className="text-left">
                {
                  this.state.dadosEnte.importacaoAlunos && <div>
                    <div className="fileinput text-center">
                      <input
                        type="file"
                        accept=".csv"
                        onChange={this.handleFile.bind(this)}
                        ref={this.fileInputRef} />
                    </div>
                    <Button
                      color="default"
                      disabled={this.state.showLoaderModal}
                      onClick={() => this.fileInputRef.current.click()}>
                      Importar alunos de arquivo
                      </Button>&nbsp;&nbsp;&nbsp;
                      <Button
                      id="btnBaixarArquivoModelo"
                      color="default"
                      disabled={this.state.showLoaderModal}
                      onClick={() => AwsUtil.open(`https://s3.sa-east-1.amazonaws.com/${env.BUCKET_NAME}/arquivos/modelos/alunos.csv`)}>
                      <i className="fa fa-download" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip placement="bottom" target="btnBaixarArquivoModelo" delay={0}>
                      Baixar arquivo modelo
                    </UncontrolledTooltip>
                  </div>
                }
              </Col>
              {
                (this.props.role === Papel.GESTOR.value
                  || !this.state.dadosEnte.bloquearCadastroAlunos) && <Col md="6" className="text-right">
                  <Button color="primary"
                    disabled={this.state.showLoaderModal}
                    onClick={() => {
                      this.props.history.push({ pathname: `${this.props.layout}/alunos/cadastro` });
                    }}>
                    Cadastrar aluno
                </Button>
                </Col>
              }
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
        <ConfirmTypingModal
          isOpen={this.state.showConfirmModal}
          callback={(senha) => {
            this.setState({ showConfirmModal: false });
            if (senha) {
              this.removerAluno(senha);
            } else {
              this.setState({ alunoSelecionado: null });
            }
          }}
          deletionInstruction='Confirme que você quer excluir o aluno digitando a sua senha no campo abaixo'
          deletionText={''}
          deletionWarning='A exclusão de um aluno é definitiva e não pode ser desfeita, sendo permitida somente se não houver nenhum cadastro relacionado ao aluno.' />
      </div>
    );
  }
}

export default withRouter(Alunos);
