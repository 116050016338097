import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Table,
  FormGroup,
  Input,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import TextoModal from 'views/components/TextoModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import FrequenciaService from 'services/FrequenciaService';
import AbonosService from 'services/AbonosService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import Papel from 'assets/csv/papeis.json';
import MotivoAusencia from 'assets/csv/motivos-ausencia.json';

class Abono extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.selectJustificativasFaltas = [
      {
        label: '1 - Tratamento de doença e de atenção à saúde do estudante',
        options: [
          MotivoAusencia._1A,
          MotivoAusencia._1B,
          MotivoAusencia._1C,
          MotivoAusencia._1D,
        ],
      },
      {
        label: '2 - Doença/óbito na família',
        options: [
          MotivoAusencia._2A,
          MotivoAusencia._2B,
        ],
      },
      {
        label: '3 - Fatos que impedem o deslocamento/acesso do estudante à escola',
        options: [
          MotivoAusencia._3A,
          MotivoAusencia._3B,
          MotivoAusencia._3C,
          MotivoAusencia._3D,
          MotivoAusencia._3E,
          MotivoAusencia._3F,
          MotivoAusencia._3G,
        ],
      },
      {
        label: '4 - Suspensão escolar',
        options: [
          MotivoAusencia._4A,
          MotivoAusencia._4B,
        ],
      },
      {
        label: '5 - Participação em atividade extraclasse/atividades híbridas (semipresenciais)',
        options: [
          MotivoAusencia._5A,
          MotivoAusencia._5B,
        ],
      },
      {
        label: '6 - Preconceito/Discriminação no ambiente escolar/bullying',
        options: [
          MotivoAusencia._6A,
        ],
      },
      {
        label: '7 - Ausência às aulas por respeito às questões sociais, culturais, étnicas ou religiosas',
        options: [
          MotivoAusencia._7A,
          MotivoAusencia._7B,
          MotivoAusencia._7C,
          MotivoAusencia._7D,
          MotivoAusencia._7E,
          MotivoAusencia._7F,
        ],
      },
      {
        label: '8 - Gravidez',
        options: [
          MotivoAusencia._8A,
          MotivoAusencia._8B,
        ],
      },
      {
        label: '9 - Situação de rua',
        options: [
          MotivoAusencia._9A,
        ],
      },
      {
        label: '10 - Trabalho infantil',
        options: [
          MotivoAusencia._10A,
          MotivoAusencia._10B,
        ],
      },
      {
        label: '11 - Violência no ambiente escolar',
        options: [
          MotivoAusencia._11A,
        ],
      },
      {
        label: '12 - Trabalho do Adolescente',
        options: [
          MotivoAusencia._12A,
          MotivoAusencia._12B,
          MotivoAusencia._12C,
          MotivoAusencia._12D,
        ],
      },
      {
        label: '13 - Exploração/Abuso Sexual',
        options: [
          MotivoAusencia._13A,
        ],
      },
      {
        label: '14 - Desinteresse/Desmotivação pelos estudos',
        options: [
          MotivoAusencia._14A,
        ],
      },
      {
        label: '15 - Abandono Escolar/Desistência',
        options: [
          MotivoAusencia._15A,
        ],
      },
      {
        label: '16 - Questões socioeconômicas, educacionais e/ou familiares',
        options: [
          MotivoAusencia._16A,
          MotivoAusencia._16B,
          MotivoAusencia._16C,
          MotivoAusencia._16D,
          MotivoAusencia._16E,
          MotivoAusencia._16F,
          MotivoAusencia._16G,
          MotivoAusencia._16H,
          MotivoAusencia._16I,
          MotivoAusencia._16J,
        ],
      },
      {
        label: '17 - Envolvimento com drogas',
        options: [
          MotivoAusencia._17A,
        ],
      },
      {
        label: '18 - Envolvimento em atos infracionais',
        options: [
          MotivoAusencia._18A,
        ],
      },
      {
        label: '19 - Violência Intrafamiliar',
        options: [
          MotivoAusencia._19A,
        ],
      },
      {
        label: '20 - Situação coletiva que impede a escola de receber seus estudantes',
        options: [
          MotivoAusencia._20A,
          MotivoAusencia._20B,
          MotivoAusencia._20C,
          MotivoAusencia._20D,
          MotivoAusencia._20E,
          MotivoAusencia._20F,
          MotivoAusencia._20G,
        ],
      },
      {
        label: '21 - Estudante Concluiu o Ensino Médio',
        options: [
          MotivoAusencia._21A,
        ],
      },
      {
        label: '22 - Óbito do estudante',
        options: [
          MotivoAusencia._22A,
        ],
      },
      {
        label: '23 - EJA Semipresencial',
        options: [
          MotivoAusencia._23A,
          MotivoAusencia._23B,
        ],
      },
      {
        label: '24 - Outros',
        options: [
          MotivoAusencia._24A,
          MotivoAusencia._24B,
        ],
      },
      {
        label: '25 - Problemas na oferta de serviços educacionais',
        options: [
          MotivoAusencia._25A,
          MotivoAusencia._25B,
          MotivoAusencia._25C,
          MotivoAusencia._25D,
          MotivoAusencia._25E,
          MotivoAusencia._25F,
          MotivoAusencia._25G,
          MotivoAusencia._25H,
        ],
      },
      {
        label: '26 - Educação bilíngue de surdos e educação especial',
        options: [
          MotivoAusencia._26A,
          MotivoAusencia._26B,
          MotivoAusencia._26C,
          MotivoAusencia._26D,
          MotivoAusencia._26E,
          MotivoAusencia._26F,
          MotivoAusencia._26G,
        ],
      },
      {
        label: '27 - Outras situações específicas',
        options: [
          MotivoAusencia._27A,
          MotivoAusencia._27B,
          MotivoAusencia._27C,
          MotivoAusencia._27D,
          MotivoAusencia._27E,
        ],
      },
    ];

    let conteudoTextoModal = '';
    this.selectJustificativasFaltas.forEach((grupo) => {
      conteudoTextoModal += `<b>${grupo.label}</b>\n`
      grupo.options.forEach((option) => {
        conteudoTextoModal += `\n${option.label}`
      });
      conteudoTextoModal += '\n\n\n';
    });



    this.validarCampos = this.validarCampos.bind(this);
    this.carregarFaltasMatricula = this.carregarFaltasMatricula.bind(this);
    this.cadastrarMotivosAusencia = this.cadastrarMotivosAusencia.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      conteudoTextoModal: <div dangerouslySetInnerHTML={{ __html: conteudoTextoModal }} />,

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.frequenciaService = new FrequenciaService();
    this.abonosService = new AbonosService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turmaMultiseriado = await this.turmasService
        .carregarTurmaMultiseriado(this.props.match.params.idTurma);

			const turmas = await this.turmasService.carregarTurmasDaTurmaMultiseriado(turmaMultiseriado.id);

			Object.assign(turmaMultiseriado, { turmas });

      const fn0 = (t) => new Promise((resolve) => resolve(
        this.diariosService.carregarDiariosDaTurma(t.id),
      ));

      const actions0 = turmaMultiseriado.turmas.map(fn0);

      const diarios = await Promise.all(actions0);

      const diario = diarios[0][0];

      const matriculas = await this.turmasService
        .carregarListaFrequenciaTurmaMultiseriado(turmaMultiseriado.id);

      matriculas.forEach((matricula) => {
        turmaMultiseriado.turmas.forEach((turma, idx) => {
          if (matricula.idTurma === turma.id) {
            Object.assign(
              matricula, { diario: diarios[idx][0] },
            );
          }
        });
      });

      const selectAlunos = matriculas.map((matricula) => ({
        label: matricula.usuario.nome,
        value: matricula.id,
        idDiario: matricula.diario.id,
      }));

      this.setState({
        anoLetivo,
        escola,
        turmaMultiseriado,
        diario,
        selectAlunos,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.alunoSelecionado) {
      this.setState({ alunoState: 'danger' });
      ret = false;
    } else {
      if (this.state.abono.quantidade + this.state.totalFaltasJustificadas > this.state.faltas.length) {
        this.setState({ faltasJustificadasState: 'has-danger' });
        ret = false;
      }
    }
    return ret;
  }

  async carregarFaltasMatricula(idDiario, idMatricula) {
    this.setState({
      carregandoFaltas: true,
    });

    try {
      const faltas = await this.frequenciaService
        .carregarFaltasMatricula(idMatricula, idDiario);

			const motivosAusenciaMatricula = await this.frequenciaService
				.carregarMotivosAusenciaPorMatricula(idDiario, idMatricula);
			
			let totalFaltasJustificadas = 0;
			faltas.forEach((frequencia) => {
				const motivosAusencia = motivosAusenciaMatricula.filter((mam) => mam.frequencia.id === frequencia.id);
				Object.assign(frequencia, { motivosAusencia });
				if (motivosAusencia && motivosAusencia.length > 0) {
					totalFaltasJustificadas++;
				}
			});

      const abono = await this.abonosService
        .carregarAbonosMatricula(idDiario, idMatricula);

      this.setState({
        abono,
        faltas,
        carregandoFaltas: false,
        totalFaltasJustificadas,
      })
    } catch (e) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar faltas do aluno',
        carregandoFaltas: false,
        faltas: undefined,
      });
    }
  }

  async cadastrarMotivosAusencia() {
    if (!this.validarCampos()) {
      return;
    }
    if (!this.state.faltas || this.state.faltas.length === 0) {
      return;
    }

    this.setState({
      showLoaderModal: true,
      showAlert: false,
    });

    try {

      this.state.faltas.forEach((frequencia) => {
        frequencia.motivosAusencia.forEach((motivoAusencia) => {
          Object.assign(motivoAusencia, {
            motivoAusencia: motivoAusencia.value,
          });
        });
      });

      await this.frequenciaService.cadastrarMotivosAusencia(
        this.state.alunoSelecionado.idDiario, this.state.alunoSelecionado.value, this.state.faltas,
      );

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Justificativas de faltas salvas com sucesso',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar justificativas de faltas',
      });
    }
  }

  getConteudoFaltas() {
    if (this.state.faltas) {
      if (this.state.faltas.length === 0) {
        return <div align="center" style={{ margin: 50 }}>
          Não há faltas registradas para o aluno selecionado.
        </div>
      } else {
        return <>
          <Row>
            <Col md="2">
              <FormGroup className={`has-label`}>
                <label>Total de faltas</label>
                <Input
                  disabled
                  type="text"
                  value={this.state.faltas.length}
                  style={{ textAlign: 'right' }}
                  tag={NumberFormat}
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup className={`has-label`}>
                <label>Faltas abonadas</label>
                <Input
                  disabled
                  type="text"
                  value={this.state.abono.quantidade}
                  style={{ textAlign: 'right' }}
                  tag={NumberFormat}
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup className={`has-label ${this.state.faltasJustificadasState}`}>
                <label>Faltas justificadas</label>
                <Input
                  disabled
                  type="text"
                  value={this.state.totalFaltasJustificadas}
                  style={{ textAlign: 'right' }}
                  tag={NumberFormat}
                />
                {this.state.faltasJustificadasState === 'has-danger' ? (
                  <label className="error">
                    Quantidade de faltas abonadas e faltas justificadas maior que total de faltas
                  </label>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Table>
            <thead className="text-primary">
              <tr>
                <th style={{ width: '10%' }}>Dia</th>
                <th style={{ width: '90%' }}>Justificativas</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.faltas.map((frequencia, idx) => <tr key={idx}>
                  <td>{frequencia.data}</td>
                  <td>
                    <Select
                      isClearable
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={'react-select primary'}
                      classNamePrefix="react-select"
                      placeholder={`Falta não justificada`}
                      value={frequencia.motivosAusencia}
                      onChange={(event) => {
                        let add = 0;
                        if(event) {
                          if (frequencia.motivosAusencia.length === 0) {
                            add = 1;
                          }
                        } else {
                          add = -1;
                        }

                        Object.assign(frequencia, {
                          motivosAusencia: event ? [event] : [],
                        });
                        
                        this.setState({
                          totalFaltasJustificadas: this.state.totalFaltasJustificadas + add,
                          faltasJustificadasState: '',
                        });
                      }}
                      options={this.selectJustificativasFaltas}
                    />
                  </td>
                </tr>)
              }
            </tbody>
          </Table>
        </>
      }
    } else if (this.state.carregandoFaltas) {
      return <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    } else {
      return null;
    }
  }

  voltar() {
    return `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/`
      + `${this.state.escola.id}/turma/multiseriado/${this.state.turmaMultiseriado.id}/diario`;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turmaMultiseriado.id}`}>{this.state.turmaMultiseriado.nome} - {this.turnos[this.state.turmaMultiseriado.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Justificativa de faltas</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Justificativa de faltas
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Aluno *</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.alunoState}`}
                      classNamePrefix="react-select"
                      value={this.state.alunoSelecionado}
                      onChange={(event) => {
                        this.setState({
                          alunoState: '',
                          alunoSelecionado: event,
                          faltas: undefined,
                        });
                        this.carregarFaltasMatricula(event.idDiario, event.value);
                      }}
                      options={this.state.selectAlunos}
                      placeholder="Aluno..."
                    />
                    {this.state.alunoState === 'danger' ? (
                      <label className="error">
                        Informe o aluno.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="8" className="buttons-row">
                  <Button
                    style={{ alignSelf: 'end' }}
                    color="primary"
                    onClick={() => this.setState({ showTextoModal: true })} >
                    Ver justificativas MEC
                  </Button>
                </Col>
              </Row>
              {
                this.getConteudoFaltas()
              }
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history.push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  {
                    this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                      disabled={!this.state.faltas || this.state.faltas.length === 0}
                      color="primary"
                      onClick={this.cadastrarMotivosAusencia} >
                      Lançar justificativas
                    </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Salvando justificativas de faltas..." />
        <TextoModal
          isOpen={this.state.showTextoModal}
          titulo={'Justificativas de faltas'}
          conteudo={this.state.conteudoTextoModal}
          closeCallback={() => this.setState({ showTextoModal: false })} />
      </div>
    );
  }
}

export default withRouter(Abono);
