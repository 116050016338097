/* eslint-disable max-len */
import React from 'react';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import LoaderModal from 'views/components/LoaderModal';
import HorariosTurma from 'views/components/HorariosTurma';

import TurmasService from 'services/TurmasService';
import MateriasService from 'services/MateriasService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';
import UsuarioService from 'services/UsuarioService';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class HorariosTurmaMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.turmasService = new TurmasService();
    this.materiasService = new MateriasService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
    this.usuarioService = new UsuarioService();
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });

    const turmaInfantil = this.props.turma.turmas.find((turma) => turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value);
    const turmaFundamental = this.props.turma.turmas.find((turma) => turma.curso.cicloEnsino === CicloEnsino.ENSINO_FUNDAMENTAL_1.value);
    const turmaEJA = this.props.turma.turmas.find((turma) => turma.curso.cicloEnsino === CicloEnsino.EJA.value);
    
    this.setState({
      turmaInfantil,
      turmaFundamental,
      turmaEJA,
      loading: false,
    });
  }

  render() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div> : <>
      <Tabs>
        <TabList>
          { this.state.turmaInfantil && <Tab>Ensino infantil</Tab> }
          { this.state.turmaFundamental && <Tab>Ensino fundamental</Tab> }
          { this.state.turmaEJA && <Tab>Educação de Jovens e Adultos</Tab> }
        </TabList>
        {
          this.state.turmaInfantil && <TabPanel>
            <HorariosTurma
              {...this.props}
              turma={this.state.turmaInfantil}
              anoLetivo={this.props.anoLetivo}
              escola={this.props.escola}
              callbackMessage={this.props.callbackMessage}
            />
          </TabPanel>
        }
        {
          this.state.turmaFundamental && <TabPanel>
            <HorariosTurma
              {...this.props}
              turma={this.state.turmaFundamental}
              anoLetivo={this.props.anoLetivo}
              escola={this.props.escola}
              callbackMessage={this.props.callbackMessage}
            />
          </TabPanel>
        }
        {
          this.state.turmaEJA && <TabPanel>
            <HorariosTurma
              {...this.props}
              turma={this.state.turmaEJA}
              anoLetivo={this.props.anoLetivo}
              escola={this.props.escola}
              callbackMessage={this.props.callbackMessage}
            />
          </TabPanel>
        }
      </Tabs>
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={`${this.state.loaderModalText}`} />
    </>;
  }
}

export default HorariosTurmaMultiseriado;
