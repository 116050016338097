import React from 'react';

import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

class Paginator extends React.Component {
  constructor(props) {
    super(props);

    const numberOfPages = Math.ceil(this.props.total / this.props.rowsPerPage);
    const start = Math.max(this.props.selectedPage - 6, 1);
    const end = Math.min(start + 6, numberOfPages);

    this.state = {
      numberOfPages,
      start,
      end,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.total !== prevProps.total
      || this.props.rowsPerPage !== prevProps.rowsPerPage
      || this.props.selectedPage !== prevProps.selectedPage) {
      
      const numberOfPages = Math.ceil(this.props.total / this.props.rowsPerPage);
      const start = Math.max(this.props.selectedPage - 6, 1);
      const end = Math.min(start + 6, numberOfPages);

      this.setState({
        numberOfPages,
        start,
        end,
      });
    }
  }

  paginatorClicked(page) {
    if (page !== this.props.selectedPage && page >= 1 && page <= this.state.numberOfPages) {
      const diff1 = this.state.end - page;
      const diff2 = page - this.state.start;
      if (diff1 < 3) {
        this.state.end = Math.min(this.state.end + 3 - diff1, this.state.numberOfPages);
        this.state.start = Math.max(this.state.end - 6, 1);
      } else if (diff2 < 3) {
        this.state.start = Math.max(this.state.start - (3 - diff2), 1);
        this.state.end = Math.min(this.state.start + 6, this.state.numberOfPages);
      }
      this.setState({ selectedPage: page });
      this.props.callback(page);
    }
  }

  createPaginator() {
    const paginator = [];
    for (let i = this.state.start; i <= this.state.end; i += 1) {
      paginator.push(
        <PaginationItem key={i} active={i === parseInt(this.props.selectedPage, 10)}>
          <PaginationLink style={{ width: 50 }} onClick={(e) => {
            e.preventDefault();
            this.paginatorClicked(i);
          }}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    }
    return paginator;
  }

  render() {
    return (
      <Pagination className="pagination-blue">
        <PaginationItem disabled={this.props.selectedPage == 1} >
          <PaginationLink style={{ width: 50, backgroundColor: '#e6e6e6' }} onClick={(e) => {
            e.preventDefault();
            this.paginatorClicked(1);
          }}>
            &laquo;
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={this.props.selectedPage == 1} >
          <PaginationLink style={{ width: 50, backgroundColor: '#e6e6e6' }} onClick={(e) => {
            e.preventDefault();
            this.paginatorClicked(this.props.selectedPage - 1);
          }}>
            &lsaquo;
          </PaginationLink>
        </PaginationItem>
        {this.createPaginator()}
        <PaginationItem disabled={this.props.selectedPage == this.state.numberOfPages}>
          <PaginationLink style={{ width: 50, backgroundColor: '#e6e6e6' }} onClick={(e) => {
            e.preventDefault();
            this.paginatorClicked(this.props.selectedPage + 1);
          }}>
            &rsaquo;
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={this.props.selectedPage == this.state.numberOfPages}>
          <PaginationLink style={{ width: 50, backgroundColor: '#e6e6e6' }} onClick={(e) => {
            e.preventDefault();
            this.paginatorClicked(this.state.numberOfPages);
          }}>
            &raquo;
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  }
}

export default Paginator;
