import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import EventosService from 'services/EventosService';
import RelatoriosService from 'services/RelatoriosEstatisticosService';

import TipoEvento from "assets/csv/tipos-evento";

class ImprimirRelatorioEventosEscolaConfirmacoesPresencas extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.eventosService = new EventosService();
    this.relatoriosService = new RelatoriosService();

    this.imprimirRelatorioEventosEscolaConfirmacoesPresencas = this.imprimirRelatorioEventosEscolaConfirmacoesPresencas.bind(this);

    this.state = {
      showAlert: false,
      error: null,

			eventos: [],
    };
  }


  async componentDidMount() {
    try {
      let data = await this.anosLetivosService.carregarAnosLetivos(1, 99999, '');
      const { anosLetivos } = data;
      anosLetivos.forEach((anoLetivo) => Object.assign(
        anoLetivo, { value: anoLetivo.id, label: anoLetivo.ano },
      ));

      data = await this.escolasService.carregarEscolas(1, 99999, '');
      let { escolas } = data;
      escolas.forEach((escola) => Object.assign(
        escola, { value: escola.id, label: escola.nome },
      ));
      /*if (this.props.role === Papel.GESTOR.value) {
        escolas = [{ value: null, label: 'Todas as escolas' }].concat(escolas);
      }*/

      this.setState({
        anosLetivos,
        escolas,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

	async carregarEventosDaEscola(idAnoLetivo, idEscola) {
		try {
			this.setState({ 
				showLoaderModal: true,
				loaderModalText: 'Carregando eventos da escola...'
			})

			const _eventos = await this.eventosService.carregarEventosDaEscola(idAnoLetivo, idEscola);
      const eventos = _eventos.filter((evento) => evento.tipo === TipoEvento.EVENTO_PRESENCIAL.value);

      eventos.forEach((evento) => Object.assign(
        evento, { value: evento.id, label: evento.titulo },
      ));

      this.setState({
        eventos,
				showLoaderModal: false,
      });
		} catch(e) {
			this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar eventos da escola.',
      });
		}
	}

  async imprimirRelatorioEventosEscolaConfirmacoesPresencas(e) {
    e.preventDefault();

    if (this.state.showLoaderModal) return;
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Imprimindo relatório de presenças confirmadas em eventos da escola para pdf...',
    });

    try {
      const pdf = await this.relatoriosService
        .imprimirRelatorioEventosEscolaConfirmacoesPresencas(this.state.eventoSelecionado.id);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Relatório de presenças confirmadas em eventos da escola impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site.',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir relatório de presenças confirmadas em eventos da escola',
      });
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.anoLetivoSelecionado) {
      this.setState({ anoLetivoState: 'danger' });
      ret = false;
    }
    if (!this.state.escolaSelecionada) {
      this.setState({ escolaState: 'danger' });
      ret = false;
    }
		if (!this.state.eventoSelecionado) {
      this.setState({ eventoState: 'danger' });
      ret = false;
    }
    return ret;
  }

  conteudoPagina() {
    return this.state.anosLetivos ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
            <BreadcrumbItem active>Relatório de presenças confirmadas em eventos da SME</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.imprimirRelatorioEventosEscolaConfirmacoesPresencas}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Imprimir relatório de presenças confirmadas em eventos da SME</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <FormGroup className={'has-label has-danger'}>
                      <label>Ano letivo *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.anoLetivoState}`}
                        classNamePrefix="react-select"
                        placeholder="Ano letivo..."
                        value={this.state.anoLetivoSelecionado}
                        onChange={(event) => {
                          this.setState({
                            anoLetivoSelecionado: event,
                            eventoSelecionado: '',
                            anoLetivoState: '',
														eventoState: '',
                          });													
                        }}
                        options={this.state.anosLetivos}
                      />
                      {this.state.anoLetivoState === 'danger' ? (
                        <label className="error">
                          Informe o ano letivo.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4" style={{ marginTop: 10 }}>
                    <FormGroup className={'has-label has-danger'}>
                      <label>Escola *</label>
                      <Select
                        isDisabled={!this.state.anoLetivoSelecionado}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.escolaState}`}
                        classNamePrefix="react-select"
                        placeholder="Escola..."
                        value={this.state.escolaSelecionada}
                        onChange={(event) => {
                          this.setState({
                            escolaSelecionada: event,
                            escolaState: '',
                          });
                          this.carregarEventosDaEscola(this.state.anoLetivoSelecionado.id, event.value);
                        }}
                        options={this.state.escolas}
                      />
                      {this.state.escolaState === 'danger' ? (
                        <label className="error">
                          Informe a escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
									<Col md="5" style={{ marginTop: 10 }}>
                    <FormGroup className={'has-label has-danger'}>
                      <label>Evento *</label>
                      <Select
                        isDisabled={!this.state.escolaSelecionada}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.eventoState}`}
                        classNamePrefix="react-select"
                        placeholder="Evento..."
                        value={this.state.eventoSelecionado}
                        onChange={(event) => {
                          this.setState({
                            eventoSelecionado: event,
                            eventoState: '',
                          });
                        }}
                        options={this.state.eventos}
                      />
                      {this.state.eventoState === 'danger' ? (
                        <label className="error">
                          Informe o evento.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/relatorios`)} >
                      Voltar
                  </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.showLoaderModal}
                      color="primary">
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default withRouter(ImprimirRelatorioEventosEscolaConfirmacoesPresencas);
