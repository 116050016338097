import React from 'react';
import { withRouter } from 'hoc/withRouter';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';
import AdaptiveTable from 'views/components/AdaptiveTable';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import SolicitacaoMatriculaService from 'services/SolicitacaoMatriculaService';

import Turno from 'assets/csv/turnos.json';
import SituacaoSolicitacaoMatricula from 'assets/csv/situacoes-solicitacao-matricula';

class MatriculasOnlineTab extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-matriculas-online-tab-${props.situacao}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.solicitacaoMatriculaService = new SolicitacaoMatriculaService();

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      error: null,

      anoLetivoSelecionado: props.anoLetivoSelecionado,
      escolaSelecionada: props.escolaSelecionada,
      situacao: props.situacao,

      dadosTabela: {
        header: [['Aluno', '25%'], ['Série', '20%'], ['Turma', '25%'], ['Turno', '10%'], ['Data', '10%']],
        columnAlign: ['text-left', 'text-left', 'text-center', 'text-center', 'text-center'],
        keys: ['nome', 'serie', 'turma', 'turno', 'data'],
        rows: [],
        total: 0,
      },
    };

    this.recusarSolicitacaoMatricula = this.recusarSolicitacaoMatricula.bind(this);
    this.confirmarRecusaSolicitacaoMatricula = this.confirmarRecusaSolicitacaoMatricula.bind(this);
    this.aceitarSolicitacaoMatricula = this.aceitarSolicitacaoMatricula.bind(this);
    this.confirmarAceiteSolicitacaoMatricula = this.confirmarAceiteSolicitacaoMatricula.bind(this);

    this.customActions = [
      {
        icon: 'fa fa-check',
        tooltip: 'Aceitar',
        color: 'success',
        callback: this.confirmarAceiteSolicitacaoMatricula,
        disabled: this.isCustomActionDisabled,
      },
      {
        icon: 'fa fa-times',
        tooltip: 'Recusar',
        color: 'danger',
        callback: this.confirmarRecusaSolicitacaoMatricula,
        disabled: this.isCustomActionDisabled,
      }
    ]
  }

  async componentDidUpdate(prevProps) {
    if (this.props.anoLetivoSelecionado !== prevProps.anoLetivoSelecionado ||
      this.props.escolaSelecionada !== prevProps.escolaSelecionada) {
        await this.carregarSolicitacoesMatricula(this.props.anoLetivoSelecionado, this.props.escolaSelecionada, 
          this.paginaSelecionada, this.linhasPorPagina, this.like)
    }
  }

  async componentDidMount() {
    await this.carregarSolicitacoesMatricula(this.props.anoLetivoSelecionado,
      this.props.escolaSelecionada, this.paginaSelecionada, this.linhasPorPagina, this.like);
  }

  isCustomActionDisabled(solicitacaoMatricula) {
    return solicitacaoMatricula.situacao !== SituacaoSolicitacaoMatricula.AGUARDANDO.value;
  }

  confirmarAceiteSolicitacaoMatricula(solicitacaoMatricula) {
    this.setState({
      solicitacaoMatriculaSelecionada: solicitacaoMatricula,
      acaoSolicitacaoMatricula: this.aceitarSolicitacaoMatricula,
      showConfirmModal: true,
      confirmModalText: 'Confirme o aceite da solicitação de matrícula.',
      confirmText: 'Aceitar',
    });
  }

  async aceitarSolicitacaoMatricula() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Aceitando solicitação de matrícula...',
    });
    this.props.callbackMessage({ showAlert: false })

    try {
      await this.solicitacaoMatriculaService
        .aceitarSolicitacaoMatricula(this.state.solicitacaoMatriculaSelecionada.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de matrícula aceita com sucesso. Matrícula realizada.',
      })

      this.carregarSolicitacoesMatricula(this.props.anoLetivoSelecionado,
        this.props.escolaSelecionada, this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao aceitar solicitação de matrícula',
      })
    }
  }

  confirmarRecusaSolicitacaoMatricula(solicitacaoMatricula) {
    this.setState({
      solicitacaoMatriculaSelecionada: solicitacaoMatricula,
      acaoSolicitacaoMatricula: this.recusarSolicitacaoMatricula,
      showConfirmModal: true,
      confirmModalText: 'Confirme a recusa da solicitação de matrícula.',
      confirmText: 'Recusar',
    });
  }

  async recusarSolicitacaoMatricula() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Recusando solicitação de matrícula...',
    });
    this.props.callbackMessage({ showAlert: false })

    try {
      await this.solicitacaoMatriculaService
        .recusarSolicitacaoMatricula(this.state.solicitacaoMatriculaSelecionada.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de matrícula recusada com sucesso.',
      })

      this.carregarSolicitacoesMatricula(this.props.anoLetivoSelecionado,
        this.props.escolaSelecionada, this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao recusar solicitação de matrícula',
      })
    }
  }

  async carregarSolicitacoesMatricula(anoLetivoSelecionado, escolaSelecionada, pagina, linhasPorPagina, like) {
    if (!anoLetivoSelecionado || !escolaSelecionada) {
      this.setState({ dadosTabela: { ...this.state.dadosTabela, total: 0 } });
      return;
    };

    try {
      this.setState({ dadosTabela: { ...this.state.dadosTabela, total: undefined } });

      const data = await this.solicitacaoMatriculaService
        .carregarSolicitacoesMatricula(anoLetivoSelecionado.id, escolaSelecionada.id,
          this.state.situacao, pagina, linhasPorPagina, like);

      data.solicitacoesMatricula.forEach((solicitacaoMatricula) => {
        Object.assign(solicitacaoMatricula, {
          nome: solicitacaoMatricula.aluno.nome,
          serie: solicitacaoMatricula.turma.curso.nome,
          turma: solicitacaoMatricula.turma.nome,
          turno: this.turnos[solicitacaoMatricula.turma.turno].label,
          data: solicitacaoMatricula.data,
        });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.solicitacoesMatricula, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar solicitações de matrícula da escola',
      });
    }
  }

  solicitacaoMatriculaSelecionada(solicitacaoMatricula) {
    this.props.history.push(`${this.props.layout}/matriculasonline/solicitacaomatricula/${solicitacaoMatricula.id}`);
  }

  render() {
    return (
      <Row>
        <Col>
          <AdaptiveTable
            selectedPage={this.paginaSelecionada}
            rowsPerPage={this.linhasPorPagina}
            disableEditAction
            disablePrintAction
            disableRemoveAction
            disableActions={this.state.situacao !== SituacaoSolicitacaoMatricula.AGUARDANDO.value}
            customActions={this.customActions}
            clickRows
            rowsPerPageCallback={(info) => {
              this.paginaSelecionada = 1;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.linhasPorPagina = info.rowsPerPage;
              sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
              this.carregarSolicitacoesMatricula(this.state.anoLetivoSelecionado,
                this.state.escolaSelecionada, this.paginaSelecionada, info.rowsPerPage, this.like);
            }}
            likeCallback={(text) => {
              this.like = text;
              this.paginaSelecionada = 1;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.carregarSolicitacoesMatricula(this.state.anoLetivoSelecionado,
                this.state.escolaSelecionada, this.paginaSelecionada, this.linhasPorPagina, text);
            }}
            paginatorCallback={(page) => {
              this.paginaSelecionada = page;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.carregarSolicitacoesMatricula(this.state.anoLetivoSelecionado,
                this.state.escolaSelecionada, this.paginaSelecionada, this.linhasPorPagina, this.like);
            }}
            rowCallback={(solicitacaoMatricula) => this.solicitacaoMatriculaSelecionada(solicitacaoMatricula)}
            data={this.state.dadosTabela} />
        </Col>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.state.acaoSolicitacaoMatricula();
            } else {
              this.setState({ solicitacaoMatriculaSelecionada: null });
            }
          }}
          confirmText={this.state.confirmText}
          cancelText="Fechar"
          text={this.state.confirmModalText} />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </Row>
    );
  }
}

class MatriculasOnline extends React.Component {
  constructor(props) {
    super(props);

    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-matriculas-online`;
    this.anoLetivoSelecionado = sessionStorage.getItem(`${this.idPagina}-anoLetivoSelecionado`);
    this.escolaSelecionada = sessionStorage.getItem(`${this.idPagina}-escolaSelecionada`);
    this.tabSelecionada = sessionStorage.getItem(`${this.idPagina}-tabSelecionada`) || 0;

    this.state = {
      showAlert: false,
      error: null,

      anoLetivoSelecionado: this.anoLetivoSelecionado ? JSON.parse(this.anoLetivoSelecionado) : undefined,
      escolaSelecionada: this.escolaSelecionada ? JSON.parse(this.escolaSelecionada) : undefined,
      tabSelecionada: this.tabSelecionada ? JSON.parse(this.tabSelecionada) : undefined,
    };
  }

  async componentDidMount() {
    try {
      let data = await this.anosLetivosService.carregarAnosLetivos(1, 99999, '');
      const { anosLetivos } = data;
      anosLetivos.forEach((anoLetivo) => Object.assign(
        anoLetivo, { value: anoLetivo.id, label: anoLetivo.ano },
      ));

      data = await this.escolasService.carregarEscolas(1, 99999, '');
      let { escolas } = data;
      escolas.forEach((escola) => Object.assign(
        escola, { value: escola.id, label: escola.nome },
      ));
      /*if (this.props.role === Papel.GESTOR.value) {
        escolas = [{ value: null, label: 'Todas as escolas' }].concat(escolas);
      }*/

      this.setState({
        anosLetivos,
        escolas,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.anosLetivos ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem active>Matrícunas Online</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Matrículas Online</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="2" style={{ marginTop: 10 }}>
                  <FormGroup className={'has-label has-danger'}>
                    <label>Ano letivo *</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.anoLetivoState}`}
                      classNamePrefix="react-select"
                      placeholder="Ano letivo..."
                      value={this.state.anoLetivoSelecionado}
                      onChange={(event) => {
                        this.setState({
                          anoLetivoSelecionado: event,
                          escolaSelecionada: '',
                          anoLetivoState: '',
                        });
                        sessionStorage.setItem(`${this.idPagina}-anoLetivoSelecionado`, JSON.stringify(event));
                      }}
                      options={this.state.anosLetivos}
                    />
                    {this.state.anoLetivoState === 'danger' ? (
                      <label className="error">
                        Informe o ano letivo.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="4" style={{ marginTop: 10 }}>
                  <FormGroup className={'has-label has-danger'}>
                    <label>Escola *</label>
                    <Select
                      isDisabled={!this.state.anoLetivoSelecionado}
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.escolaState}`}
                      classNamePrefix="react-select"
                      placeholder="Escola..."
                      value={this.state.escolaSelecionada}
                      onChange={(event) => {
                        this.setState({
                          escolaSelecionada: event,
                          escolaState: '',
                        });
                        sessionStorage.setItem(`${this.idPagina}-escolaSelecionada`, JSON.stringify(event));
                      }}
                      options={this.state.escolas}
                    />
                    {this.state.escolaState === 'danger' ? (
                      <label className="error">
                        Informe a escola.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <br />
              {
                this.state.anoLetivoSelecionado && this.state.escolaSelecionada && <Row>
                  <Col>
                    <Tabs defaultIndex={this.state.tabSelecionada}>
                      <TabList>
                        <Tab onClick={() => sessionStorage.setItem(`${this.idPagina}-tabSelecionada`, 0)}>Aguardando</Tab>
                        <Tab onClick={() => sessionStorage.setItem(`${this.idPagina}-tabSelecionada`, 1)}>Aceitas</Tab>
                        <Tab onClick={() => sessionStorage.setItem(`${this.idPagina}-tabSelecionada`, 2)}>Recusadas</Tab>
                        <Tab onClick={() => sessionStorage.setItem(`${this.idPagina}-tabSelecionada`, 3)}>Canceladas</Tab>
                      </TabList>
                      <TabPanel>
                        <MatriculasOnlineTab
                          history={this.props.history}
                          layout={this.props.layout}
                          dadosUsuario={this.props.dadosUsuario}
                          anoLetivoSelecionado={this.state.anoLetivoSelecionado}
                          escolaSelecionada={this.state.escolaSelecionada}
                          situacao={SituacaoSolicitacaoMatricula.AGUARDANDO.value}
                          callbackMessage={(msg) => this.setState(msg)} />
                      </TabPanel>
                      <TabPanel>
                        <MatriculasOnlineTab
                          history={this.props.history}
                          layout={this.props.layout}
                          dadosUsuario={this.props.dadosUsuario}
                          anoLetivoSelecionado={this.state.anoLetivoSelecionado}
                          escolaSelecionada={this.state.escolaSelecionada}
                          situacao={SituacaoSolicitacaoMatricula.ACEITA.value}
                          callbackMessage={(msg) => this.setState(msg)} />
                      </TabPanel>
                      <TabPanel>
                        <MatriculasOnlineTab
                          history={this.props.history}
                          layout={this.props.layout}
                          dadosUsuario={this.props.dadosUsuario}
                          anoLetivoSelecionado={this.state.anoLetivoSelecionado}
                          escolaSelecionada={this.state.escolaSelecionada}
                          situacao={SituacaoSolicitacaoMatricula.RECUSADA.value}
                          callbackMessage={(msg) => this.setState(msg)} />
                      </TabPanel>
                      <TabPanel>
                        <MatriculasOnlineTab
                          history={this.props.history}
                          layout={this.props.layout}
                          dadosUsuario={this.props.dadosUsuario}
                          anoLetivoSelecionado={this.state.anoLetivoSelecionado}
                          escolaSelecionada={this.state.escolaSelecionada}
                          situacao={SituacaoSolicitacaoMatricula.CANCELADA.value}
                          callbackMessage={(msg) => this.setState(msg)} />
                      </TabPanel>
                    </Tabs>
                  </Col>
                </Row>
              }
            </CardBody>
          </Card>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(MatriculasOnline);