import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
} from 'reactstrap';

import TextareaAutosize from 'react-textarea-autosize';

class GetStringModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerText: props.headerText,
      confirmButtonText: props.confirmButtonText,
      intructionText: props.intructionText,
      typedText: props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        headerText: prevProps.headerText,
        confirmButtonText: prevProps.confirmButtonText,
        intructionText: prevProps.intructionText,
        typedText: prevProps.value,
      });
    }
  }

  validarCampos() {
    if (this.state.typedText === '') {
      this.setState({ typeTextState: 'has-danger' });
      return;
    }

    this.props.callback(this.state.typedText);

    this.setState({ typedText: '' });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        size="xl">
        <ModalBody>
          <br />
          <Row>
            <Col md="12" className="text-center">
              <b>{this.state.headerText}</b>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="text-center">
              <FormGroup className={`has-label ${this.state.typeTextState}`}>
                <TextareaAutosize
                  className={`autoheight ${this.state.typeTextState}`}
                  value={this.state.typedText}
                  onChange={(evt) => this.setState({
                    typedText: evt.target.value,
                    typedTextState: '',
                  })} />
                {this.state.typeTextState === 'has-danger' ? (
                  <label className="error">
                    {this.state.headerText}
                  </label>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <br />
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.setState({ typedText: '', typeTextState: '' });
              this.props.callback(undefined);
            }}>
            Cancelar
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => this.validarCampos()}>
            {this.props.confirmButtonText}
          </Button>&nbsp;&nbsp;&nbsp;
        </ModalFooter>
      </Modal>
    );
  }
}

export default GetStringModal;
