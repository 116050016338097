import React from 'react';

import {
	Modal,
	ModalBody,
	Table,
	Button,
	ModalFooter,
	Row,
	Col,
} from 'reactstrap';

class UsuariosExistentesModal extends React.Component {

	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				className="usuarios-existentes-modal">
				<ModalBody>
					<br />
					<Row>
						<Col md="12" className="text-center">
							<h3>ATENÇÃO!!!</h3>
						</Col>
					</Row>
					<Row>
						<Col md="12" className="text-center">
							<font color="red">Existem alunos semelhantes já cadastrados no sistema, exibidos na tabela abaixo. 
								Não prossiga com o cadastro caso um dos alunos abaixo seja o aluno que você está tentando cadastrar.</font>
						</Col>
					</Row>
					<br />
					<div align="center" style={{ paddingTop: '40px', paddingBottom: '40px' }}>
						<Table>
							<thead className="text-primary">
								<tr>
									<th>Nome</th>
									<th>Nome da mãe</th>
									<th className="text-center">Data de nascimento</th>
									<th className="text-center">CPF</th>
								</tr>
							</thead>
							<tbody>
								{
									this.props?.usuarios?.map((usuario, i) => <tr key={i}>
										<td>{usuario.nome}</td>
										<td>{usuario.nomeMae}</td>
										<td className="text-center">{usuario.dataNascimento}</td>
										<td className="text-center">{usuario.cpf}</td>
									</tr>
									)
								}
							</tbody>
						</Table>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color="default"
						data-dismiss="modal"
						type="button"
						onClick={() => { this.props.callback(false); }}>
						Cancelar
					</Button>
					<Button
						color="primary"
						type="button"
						onClick={() => { this.props.callback(true); }}>
						Cadastrar
					</Button>&nbsp;&nbsp;&nbsp;
				</ModalFooter>
			</Modal>
		);
	}
}

export default UsuariosExistentesModal;
