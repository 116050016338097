import React from 'react';
import {
  Alert,
} from 'reactstrap';

class _Alert extends React.Component {
	componentDidUpdate(prevProps) {
    if (this.props.isOpen && this.props.isOpen != prevProps.isOpen) {
      const el = document.getElementById('navbarsisaeduc');
      if (el) {
        el.scrollIntoView();
      }
    }
  }
	
  render() {
    return (
      <Alert {...this.props}></Alert>
    );
  }
}

export default _Alert;
