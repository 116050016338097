import BaseService from './BaseService';

class RegistroAulasService extends BaseService {

  cadastrarRegistroAula(registroaula) {
    const requestInfo = {
      method: 'post',
      url: '/registroaula',
      data: JSON.stringify(registroaula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarRegistroAula(registroaula) {
    const requestInfo = {
      method: 'put',
      url: '/registroaula',
      data: JSON.stringify(registroaula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerRegistroAula(idRegistroAula) {
    const requestInfo = {
      method: 'delete',
      url: `/registroaula/${idRegistroAula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  cadastrarRegistroAulaAgrupado(registroaula) {
    const requestInfo = {
      method: 'post',
      url: '/registroaula/agrupado',
      data: JSON.stringify(registroaula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  atualizarRegistroAulaAgrupado(registroaula) {
    const requestInfo = {
      method: 'put',
      url: '/registroaula/agrupado',
      data: JSON.stringify(registroaula),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerRegistroAulaAgrupado(idRegistroAula) {
    const requestInfo = {
      method: 'delete',
      url: `/registroaula/${idRegistroAula}/agrupado`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarRegistroAula(idRegistroAula) {
    const requestInfo = {
      method: 'get',
      url: `/registroaula/${idRegistroAula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarRegistroAulas(idDiario, pagina, linhasPorPagina, like) {
    let url = `/registroaula/diario/${idDiario}/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  enviarPlanilha(idDiario, arquivo) {
    const url = `/registroaula/diario/${idDiario}/enviar/planilha`;

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  enviarPlanilhaMultiseriadoAgrupado(idDiario, arquivo) {
    const url = `/registroaula/diario/${idDiario}/enviar/planilha/multiseriado/agrupado`;

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  downloadPlanilha(idDiario) {
    const url = `/registroaula/diario/${idDiario}/download/planilha`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTotalAulasLancadas(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/registroaula/diario/${idDiario}/totalaulaslancadas`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarObjetivosAprendizagem(idRegistroAula) {
    const requestInfo = {
      method: 'get',
      url: `/registroaula/${idRegistroAula}/objetivosaprendizagem`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarObjetivosAprendizagemPorAgrupador(idDiario, idAgrupador) {
    const requestInfo = {
      method: 'get',
      url: `/registroaula/diario/${idDiario}/agrupador/${idAgrupador}/objetivosaprendizagem`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarHabilidades(idRegistroAula) {
    const requestInfo = {
      method: 'get',
      url: `/registroaula/${idRegistroAula}/habilidades`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarHabilidadesPorAgrupador(idDiario, idAgrupador) {
    const requestInfo = {
      method: 'get',
      url: `/registroaula/diario/${idDiario}/agrupador/${idAgrupador}/habilidades`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMateriasRegistroAula(idRegistroAula) {
    const requestInfo = {
      method: 'get',
      url: `/registroaula/${idRegistroAula}/materias`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  replicarRegistroAula(idRegistroAula, diarios) {
    const url = `/registroaula/${idRegistroAula}/replicar`;

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(diarios),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default RegistroAulasService;
