import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';
// reactstrap components
import {
	Card,
	CardHeader,
	CardTitle,
	CardBody,
	CardFooter,
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	Table,
	Button,
} from 'reactstrap';

class RelatoriosDistorcaoIdadeSerie extends React.Component {
	render = () => <div className="content">
		<Row>
			<Col md="12">
				<Breadcrumb>
					<BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
					<BreadcrumbItem active>Relatórios de escolas e turmas</BreadcrumbItem>
				</Breadcrumb>
			</Col>
		</Row>
		<Row>
			<Col>
				<Card>
					<CardHeader>
						<CardTitle></CardTitle>
					</CardHeader>
					<CardBody>
						<Table hover>
							<thead className="text-primary">
								<tr>
									<th>Relatórios de escolas e turmas</th>
								</tr>
							</thead>
							<tbody>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/turmasporescola`)}>
									<td>Relatório de turmas por escola</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/turmasporserie`)}>
									<td>Relatório de quantidade de turmas por série</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/diariosporescola`)}>
									<td>Relatório de diários por escola</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/funcionariosporescola`)}>
									<td>Relação de funcionários por escola</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/ocorrenciasporescola`)}>
									<td>Relatório de ocorrências por escola</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/justificativasfaltasporturma`)}>
									<td>Relação de justificativas de faltas por turma</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/livromatriculaporescola`)}>
									<td>Relatório livro de matrículas por escola</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/livromatriculaporturma`)}>
									<td>Relatório livro de matrículas por turma</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/notasfinaisporturma`)}>
									<td>Relatório de notas finais por turma</td>
								</tr>
								{ /*
                  <tr
                    className='tr-clickable'
                    onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/fichasindividuaisporturma`)}>
                    <td>Relatório de fichas individuais de alunos por turma</td>
                  </tr>
                  */ }
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/frequenciasmensaisporturma`)}>
									<td>Relatório de frequências mensais por turma</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/quantidadefrequenciadia`)}>
									<td>Relatório de quantidade de frequência lançada por dia</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/listagemrelatoriosturmasnaoinformadosporescola`)}>
									<td>Listagem de relatórios de turmas não informados por escola</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/fardamentoalunosporescola`)}>
									<td>Relatório de fardamento de alunos por escola</td>
								</tr>
								<tr
									className='tr-clickable'
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas/totalfardamentoporescola`)}>
									<td>Relatório de total de fardamento por escola</td>
								</tr>
							</tbody>
						</Table>
					</CardBody>
					<CardFooter>
						<Row>
							<Col md="6" className="text-left">
								<Button
									color="primary"
									onClick={() => this.props.history.push(`${this.props.layout}/relatorios`)} >
									Voltar
								</Button>
							</Col>
						</Row>
					</CardFooter>
				</Card>
			</Col>
		</Row>
	</div>
}

export default withRouter(RelatoriosDistorcaoIdadeSerie);
