import ImageResize from "quill-image-resizer-plugin";
import { useCallback, useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import env from 'conf/env.json';

const Font = Quill.import('formats/font');
//Font.whitelist = ['arial', 'times-new-roman', 'courier'];
Quill.register(Font, true);
Quill.register("modules/imageResize", ImageResize);

function QuillEditor({ value, onChange, callbackMessage, callbackUpload, state, readOnly, hideImage, hideVideo, noBorder }) {

  const quillRef = useRef(null);

  const fileInputRef = useRef();
  const fileInputRef2 = useRef();

  const fileChange = useCallback((e) => {
    e.preventDefault();

    callbackMessage({ showAlert: false });

    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 5000000) {
          callbackMessage({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Imagem muito grande. O tamanho máximo para imagem é 5MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            callbackMessage({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            try {
              callbackMessage({
                buttonDisabled: true,
                loaderModalText: 'Fazendo upload...',
              });

              const arquivo = {
                nome: file.name,
                conteudo: matches[4],
                extensao: ext,
                tipo: matches[1],
              };

              const url = await callbackUpload(arquivo);
              const key = url.split(`${env.BUCKET_NAME}/`)[1];
              const urlToken = `${env.BACKEND_URL}/resource/arquivonoticia/${key}`;

              if (urlToken) {
                if (matches[2] === 'image') {
                  const html = `<img src="${urlToken}"></img>`;
                  const quill = quillRef.current.getEditor();
                  quill.clipboard.dangerouslyPasteHTML(quill.getSelection().index, html);
                } else {
                  const html = `<a href="${urlToken}">${file.name}</a>`;
                  const quill = quillRef.current.getEditor();
                  quill.clipboard.dangerouslyPasteHTML(quill.getSelection().index, html);
                }
              }

              callbackMessage({ buttonDisabled: false });
            } catch (msg) {
              callbackMessage({
                buttonDisabled: false,
                showAlert: true,
                alertColor: 'danger',
                alertMsg: msg || 'Erro ao carregar arquivo selecionado.',
              });
            }
          }
        }
      };
      reader.readAsDataURL(file);
    }

    e.target.value = "";
  }, [callbackMessage, callbackUpload]);

  const modules = useMemo(() => ({
    toolbar: readOnly ? false : {
      container: [
        [{ font: Font.whitelist }],
        [{ size: [] }],
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'filebutton', hideImage ? undefined : 'image', hideVideo ? undefined : 'video'],
        ['clean'],
      ],
      handlers: {
        'filebutton': () => fileInputRef2.current.click(),
        'image': () => fileInputRef.current.click(),
      },
    },
    imageResize: readOnly ? undefined : {},
  }), []);

  const formats = useMemo(() => ([
    'font', 'size', 'header', 'bold', 'italic', 'underline', 'strike',
    'color', 'background', 'list', 'bullet', 'align', 'indent', 'link', 'image', 'video', 'width'
  ]), []);

  return <>
    <div className="fileinput text-center">
      <input
        type="file"
        accept="image/jpeg, image/png"
        onChange={fileChange}
        ref={fileInputRef} />
      <input
        type="file"
        onChange={fileChange}
        ref={fileInputRef2} />
    </div>
    <ReactQuill
      className={`${state ? state : ''} ${noBorder ? 'no-border' : ''} ${readOnly ? 'read-only' : ''}`}
      readOnly={readOnly}
      ref={quillRef}
      theme="snow"
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
    />
  </>;
}

export default QuillEditor;